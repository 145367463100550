import {Card, Col, Row} from "react-bootstrap";
import React, {PropsWithChildren} from "react";

export type ListCardItem = {
    name: React.ReactNode,
    img: string,
    url: string,
    description : React.ReactNode
}

export interface ListCardProps extends PropsWithChildren<any> {
    item: ListCardItem
}

export const ListCard: React.FC<ListCardProps> = (props) => {
    return (
            <Card className={'mb-3'}>
                <Row className="g-0 align-items-center">
                    <Col md={4}>
                        <a href={props.item.url}>
                        <Card.Img variant="top" src={props.item.img} style={{
                            width: "100%",
                            height: "200px",
                            objectFit: "contain",
                            padding: "10px",
                            borderBottom: "1px solid rgb(223,223,223)"
                        }}/>
                        </a>
                    </Col>
                    <Col md={8}>
                        <Card.Body className="card-body" style={{
                            textAlign : "left",
                        }}>
                            <Card.Title>{props.item.name}</Card.Title>
                            <p>{props.item.description}</p>
                            {/*<p className="card-text"><small className="text-muted">Date: 24-12-2018</small></p>*/}
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
    );
}
