import React, {PropsWithChildren} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {LinkItem, LinkType} from "./LinkItem";

export type PaperCardItem = {
    title: React.ReactNode,
    img: string
    url: string
    authors: string,
    conference: string,
    awards ?: string[],
    links : LinkType[]
}

export interface PaperCardProps extends PropsWithChildren<any> {
    item: PaperCardItem
}

export const PaperCard: React.FC<PaperCardProps> = (props) => {
    return (<Card className={'mb-3'}>
        <Row className="g-0 align-items-center">
            <Col md={4}>
                <a href={props.item.url}>
                    <Card.Img variant="top" src={props.item.img} style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "contain",
                        padding: "10px",
                        borderBottom: "1px solid rgb(223,223,223)"
                    }}/>
                </a>
            </Col>
            <Col md={8}>
                <Card.Body className="card-body" style={{
                    textAlign : "left",
                }}>
                    <Card.Title>{props.item.title}</Card.Title>
                    <i>{props.item.authors}</i>
                    <br />
                    <i>{props.item.conference}</i>
                    <br />
                    {props.item.links.map((link,i) => <LinkItem key={i} link={link} color={"#565555"} style={{
                        padding:"3px"
                    }}
                    /> )}
                    <br/>
                    {props.item.awards?.map((award, i) => <span key={i}>Award: <b>{award}</b><br /></span>)}
                </Card.Body>
            </Col>
        </Row>
    </Card>);
}
