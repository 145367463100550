import {Row} from "react-bootstrap";
import React, {PropsWithChildren} from "react";

export interface ListViewViewProps extends PropsWithChildren<any> {
    items: React.ReactNode[]
}

export const ListView: React.FC<ListViewViewProps> = (props) => {
    return (<Row>{props.items.map((item) => item)}</Row>);
}
