import {Card, Carousel, Col, Container, Image, Row} from "react-bootstrap";
import React from "react";
import {Section} from "../components/Section";
import {ListView} from "../components/ListView";
import {ListCard, ListCardItem} from "../components/views/ListCard";
import {LinkCard, LinkCardItem} from "../components/views/LinkCard";
import {GridView} from "../components/GridView";
import {PaperCard, PaperCardItem} from "../components/views/PaperCard";
import {VideoCard, VideoCardItem} from "../components/views/VideoCard";

export const Home: React.FC = () => {
    const newItems: ListCardItem[] = [
        {
            name: <span>Winner Gerard & Anton Awards 2023</span>,
            img: "media/gaaward_tibo.jpg",
            url: "https://www.tue.nl",
            description: <span>
                Every year the BrainPort region identifies the top 10 most promising tech startups and Tibo Energy was selected!<br/>
                Check out the article <a href={"https://innovationorigins.com/en/10-1-gerard-anton-awards-here-are-the-2023-winners/"}>here</a>.
            </span>
        },
        {
            name: <span>Best Teacher Data Science and Artificial Intelligence</span>,
            img: "prizes/tue.png",
            url: "https://www.tue.nl",
            description: <span>
                Together with prof. dr. Anna Vilanova we have been nominated for the best Teacher in the Data-Science & Artificial Intelligence program 2022!
            </span>
        },
        {
            name: <span><b>Tibo Energy</b>: Energy orchestration technology for companies and campuses</span>,
            img: "media/tibo.svg",
            url: "https://www.tibo.energy",
            description: <span>
               <span>
                We have setup a company <a href="http://www.tibo.energy"><b>Tibo Energy</b></a> to help companies manage their energy consumption using Digital Twin Technology. Green Energy should not go to waste! Check out <a href="/trac">awards, grants, and traction</a></span>
            </span>
        },
        {
            name: <span><b>DutchDataBusters:</b> bridging the gap between Industry and Academia</span>,
            img: "media/ddb.png",
            url: "https://www.dutchdatabusters.nl",
            description: <span>
                Dutchdatabusters connects students and Industry in the field of Data Science.
                With out guidance students can kickstart their career in Industry while companies don't end up with just an academic report.
                <b>Check out</b> our <a
                href={"https://www.dutchdatabusters.nl"}>website</a> for more information.</span>
        },
        {
            name:
                <span><b>Check out</b> our new article in the <b>European Cyber Security Perspectives 2020!</b></span>,
            img: "media/escp2020.png",
            url: "media/escp2020.pdf",
            description: <span>The European Cyber Security Perspectives is a partnership of the Dutch National Police,
                the Dutch National Cyber Security Centre and a number of companies including KPN. The purpose of the report is
                to collaborate between the parties and share essential cybersecurity knowledge.</span>
        },
        {
            name: <span><b>Check out</b> our company <b>CodeNext21</b></span>,
            img: "media/codenext21.svg",
            url: "https://www.codenext21.com",
            description: <span>
                We have setup a company <a href="http://www.codenext21.com"><b>CodeNext21</b></a> to make our clear-box technology accessible for Industry. Check out <a href="/trac">awards, grants, and traction</a></span>
        },
        {
            name: <span>Elsevier Weekblad: 30 onder de 30</span>,
            img: "media/ew30.jpg",
            url: "https://mdrresearch.nl/wordpress/wp-content/uploads/27_NED-30-ONDER-30-PLUS-3.pdf",
            description: <span>
                Every year Elsevier's Weekblad highlights 30 people under the age of 30 who have done remarkable work given their age.<br/>
                Deeply honored to be chosen as part of this group!<br/>
                Check out the magazine <a
                href={"https://mdrresearch.nl/wordpress/wp-content/uploads/27_NED-30-ONDER-30-PLUS-3.pdf"}>here</a>.
            </span>
        },
        {
            name: <span>Honorable Mention Gerrit van Dijk Award 2019</span>,
            img: "media/gerritvandijk.jpg",
            url: "https://www.linkedin.com/feed/update/urn:li:activity:6546673076246056960",
            description: <span>
                My Thesis Interactive Visualization of Event Logs for Cybersecurity has won an Honorable mention for the <a
                href="https://www.nederlandsedatascienceprijzen.nl/">Gerrit van Dijk prijs 2019!!!</a>
                <br/>
                <b>Check out the <a
                    href="https://www.linkedin.com/feed/update/urn:li:activity:6546673076246056960">article</a>.</b></span>
        },
        {
            name:
                <span><b>Check out</b> our new article in the <b>European Cyber Security Perspectives 2019!</b></span>,
            img: "traction/kpn2.jpg",
            url: "media/escp2019.pdf",
            description: <span>The European Cyber Security Perspectives is a partnership of the Dutch National Police,
                the Dutch National Cyber Security Centre and a number of companies including KPN. The purpose of the report is
                to collaborate between the parties and share essential cybersecurity knowledge.</span>
        },
        {
            name: <span>Nomination TU/e Academic Awards 2019</span>,
            img: "prizes/tue.png",
            url: "https://flippingbooks.tue.nl/internet/academic_awards_2019/34/",
            description: <span>
                <a href={"/files/Cappers2018.pdf"}>My thesis</a> is nominated for the TU/e Academic awards 2019!
                <br/>
                The thesis is chosen as the <a href={"https://flippingbooks.tue.nl/internet/academic_awards_2019/34/"}>best PhD thesis of Mathematics and Computer Science department</a>
               </span>
        },
        {
            name: <span>Phd Dissertation</span>,
            img: "media/cover.png",
            url: "files/Cappers2018.pdf",
            description: <span>
                Check out my dissertation on <a href={"/files/Cappers2018.pdf"}><b>Interactive Visualization of Events Logs for Cybersecurity</b></a>.
               </span>
        },
        {
            name: <span><b>Eventpad</b> is fighting Ransomware</span>,
            img: "flashes/vizsec20182.jpg",
            url: "https://vimeo.com/308134460",
            description: <span>
                Check out my talk at <a href="https://vimeo.com/308134460"><b>VizSec 2018!</b></a>
                <br/>
                Eventpad: Rapid Malware Analysis and Reverse Engineering using Visual Analytics
            </span>
        },
        {
            name: <span>Best Executive Summary European Venture Program 2018</span>,
            img: "media/evp2.jpg",
            url: "https://innovationorigins.com/nl/munchense-startup-wint-eurotech-pitchwedstrijd-in-eindhoven/",
            description: <span>
                5 countries, 22 startups, 1 best paper.
                <br/>
                EventPad has won the best 2-pager award at the European Venture Program 2018! Read more about the program <a
                href={"https://innovationorigins.com/nl/munchense-startup-wint-eurotech-pitchwedstrijd-in-eindhoven/"}>here</a>.
               </span>
        },
        {
            name: <span>Eventpad: "Welcome to BlackHat 2018"</span>,
            img: "media/blackhat.png",
            url: "https://www.blackhat.com/us-18/",
            description: <span>
                <b>EventPad</b> is reaching for the stars! Getting ready for <a
                href={"https://www.blackhat.com/us-18/"}><b>Black Hat USA Arsenal 2018!</b></a>
               </span>
        },
        {
            name:
                <span><b>Check out</b> our new article in the <b>European Cyber Security Perspectives 2018!</b></span>,
            img: "media/escp2018.jpg",
            url: "media/escp2018.pdf",
            description: <span>The European Cyber Security Perspectives is a partnership of the Dutch National Police,
                the Dutch National Cyber Security Centre and a number of companies including KPN. The purpose of the report is
                to collaborate between the parties and share essential cybersecurity knowledge.</span>
        },
        {
            name: <span><b>EventPad</b> strikes again! <b>Best Demo and Technology</b> award</span>,
            img: "media/ictopenaward.png",
            url: "traction/tueict.pdf",
            description: <span>
                <b>EventPad</b> won the <a
                href={"traction/tueict.pdf"}><b>ICTOpen 2018</b></a> award for Best Demo and Technology,
                <br/>
                The Dutch Scientific Institute NWO announced Eventpad as the most impactful Dutch technology for ICT environments and industries of 2018!
               </span>
        },
        {
            name: <span><b>EventPad</b> winner <b>Visual Analytics Challenge 2017</b></span>,
            img: "media/VASTChallenge.jpg",
            url: "https://medium.com/@TUeindhoven/m-cs-phd-candidate-wins-international-challenge-in-the-field-of-visual-analytics-68594a1d4333",
            description: <span>
                <b>EventPad</b> has won the <a
                href={"https://medium.com/@TUeindhoven/m-cs-phd-candidate-wins-international-challenge-in-the-field-of-visual-analytics-68594a1d4333"}>
                <b>VAST Challenge 2017</b></a> award for <b>Elegant Support For Hypothesis Generation and Testing</b>!
                <br/>
                Check out my paper: <a href={"papers/lekagul.pdf"}>Exploring Lekagul Sensor Events using Rules, Aggregations, and Selections</a>
               </span>
        },
        {
            name: <span>Check out my talk at <b>Still Hacking Anyway 2017</b>!</span>,
            img: "media/sha2017.png",
            url: "https://media.ccc.de/v/SHA2017-369-network_traffic_analysis_using_deep_packet_inspection_and_data_visualization#video",
            description: <span>
                Network Traffic Analysis using <b>Deep Packet Inspection</b> and <b>Data Visualization</b>
                Eventpad: the Notepad editor for event data.
               </span>
        },
        {
            name: <span>Check out the demo of my new system <b>EventPad</b></span>,
            img: "media/eventPad.png",
            url: "https://www.youtube.com/watch?v=2DWVW-vLN8Q",
            description: <span>
                Check out the <a href="https://www.event-pad.com">Eventpad website</a> for demos and downloads Eventpad
                <br/>
                Also check the<a href="videos/eventPadff.mp4">fastForward</a> presentation and <a
                href="videos/eventPadPreview.mp4">preview</a> video
               </span>
        },
        {
            name: <span>Check out my talk at <b>VizSec 2016</b>!</span>,
            img: "flashes/vizsec2016.png",
            url: "https://www.bramcappers.nl/files/videos/vizsec2016.mp4",
            description: <span>
                Title of my talk: Understanding the <b>C</b>ontext <b>o</b>f <b>N</b>etwork <b>T</b>raffic <b>A</b>lerts
                <br/>
                Check out the reactions on <a href="https://twitter.com/search?q=Vizsec%20Cappers&src=typd">Twitter</a>!
               </span>
        },
    ];

    const paperItems: PaperCardItem[] = [
        {
            title: "Eventpad: Rapid Malware Analysis and Reverse Engineering using Visual Analytics",
            img: "https://img.youtube.com/vi/g4brXOtPELI/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=g4brXOtPELI&feature=youtu.be",
            authors: "Bram C.M. Cappers, Paulus N. Meessen, Sandro Etalle and Jarke J. van Wijk",
            conference: "Proceedings of the IEEE Symposium on Visualization for Cyber Security (VizSec), (2018), pages 1-8",
            links: [
                {
                    type: "pdf",
                    url: "papers/ransom.pdf"
                },
                {
                    type: "video",
                    url: "https://www.youtube.com/watch?v=g4brXOtPELI&feature=youtu.be"
                },
                {
                    type: "presentation",
                    url: "slides/vizsec2018.pptx"
                }
            ]
        },
        {
            title: "Exploring Lekagul Sensor Events using Rules, Aggregations, and Selections.",
            img: "https://img.youtube.com/vi/IBgJ3R9cAvQ/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=2DWVW-vLN8Q",
            authors: "Bram C.M. Cappers",
            conference: "Visual Analytics Science and Technology (VAST) Challenge, (2017)",
            awards: ["Visualization award “Elegant Tool for Hypothesis Testing and Generation", "Best Poster Award 2017"],
            links: [
                {
                    type: "pdf",
                    url: "papers/lekagul.pdf"
                },
                {
                    type: "pdf",
                    url: "papers/vast2017poster.pdf"
                },
                {
                    type: "video",
                    url: "https://www.youtube.com/watch?v=IBgJ3R9cAvQ"
                },
                {
                    type: "presentation",
                    url: "slides/vast2017.pptx"
                }
            ]
        },
        {
            title: "Exploring Multivariate Event Sequences using Rules, Aggregations and Selections.",
            img: "https://img.youtube.com/vi/2DWVW-vLN8Q/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=2DWVW-vLN8Q",
            authors: "Bram C.M. Cappers and Jarke J. van Wijk.",
            conference: "IEEE Transactions on Visualization and Computer Graphics, (2018), 24, 1, 532-541",
            links: [
                {
                    type: "pdf",
                    url: "papers/eventPad.pdf"
                },
                {
                    type: "video",
                    url: "https://www.youtube.com/watch?v=2DWVW-vLN8Q"
                },
                {
                    type: "video",
                    url: "videos/eventPadff.mp4"
                },
                {
                    type: "video",
                    url: "videos/eventPadPreview.mp4"
                },
            ]
        },
        {
            title: "Understanding the Context of Network Traffic Alerts.",
            img: "https://img.youtube.com/vi/yOXDZYKCKZ0/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=yOXDZYKCKZ0",
            authors: "Bram C.M. Cappers and Jarke J. van Wijk.",
            conference: "Proceedings of the IEEE Symposium on Visualization for Cyber Security (VizSec), (2016), pages 1-8",
            links: [
                {
                    type: "pdf",
                    url: "papers/context.pdf"
                },
                {
                    type: "video",
                    url: "https://www.youtube.com/watch?v=yOXDZYKCKZ0"
                },
                {
                    type: "presentation",
                    url: "slides/conta.pptx"
                },
            ]
        },
        {
            title: "SNAPS: Network traffic Analysis through Projection and Selection.",
            img: "https://i.ytimg.com/vi/aYywTOYjYDA/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=aYywTOYjYDA",
            authors: "Bram C.M. Cappers and Jarke J. van Wijk.",
            conference: "Proceedings of the IEEE Symposium on Visualization for Cyber Security (VizSec), (2015), pages 1-8",
            links: [
                {
                    type: "pdf",
                    url: "papers/snaps.pdf"
                },
                {
                    type: "video",
                    url: "https://www.youtube.com/watch?v=aYywTOYjYDA"
                },
                {
                    type: "presentation",
                    url: "slides/snaps.pptx"
                },
            ]
        },
        {
            title: "Anomaly Detection 101: Hunting the Unknown",
            img: "media/escp2020.png",
            url: "media/escp2020.pdf",
            authors: "Bram C.M. Cappers, Dennis G.M. Cappers, Joey van de Pasch, and Josh G.M. Mengerink",
            conference: "Seventh European Cyber Security Perspectives Report 2020, pages 59-61",
            links: [
                {
                    type: "pdf",
                    url: "media/escp2020.pdf"
                }
            ]
        },
        {
            title: "Anomaly Detection 101: Hunting the Unknown",
            img: "media/escp2019.jpg",
            url: "media/escp2019.pdf",
            authors: "Bram C.M. Cappers, Josh G.M. Mengerink, and J. van de Pasch",
            conference: "Sixth European Cyber Security Perspectives Report 2019, pages 76-78",
            links: [
                {
                    type: "pdf",
                    url: "media/escp2019.pdf"
                }
            ]
        },
        {
            title: "Eventpad: A Visual Analytics approach to Network Intrusion Detection and Reverse Engineering",
            img: "media/escp2018.jpg",
            url: "media/escp2018.pdf",
            authors: "Bram C.M. Cappers, Jarke J. van Wijk, and S. Etalle",
            conference: "Fifth European Cyber Security Perspectives Report 2018, pages 62-65",
            links: [
                {
                    type: "pdf",
                    url: "media/escp2018.pdf"
                }
            ]
        },
        {
            title: "Semantic Network Traffic Analysis using Deep Packet Inspection and Visual Analytics.",
            img: "media/ictopen.jpeg",
            url: "papers/ictopenPaper.pdf",
            authors: "Bram C.M. Cappers.",
            conference: "ICT.Open 2017",
            links: [
                {
                    type: "pdf",
                    url: "papers/ictopenPaper.pdf"
                },
                {
                    type: "pdf",
                    url: "papers/ictopenPoster.pdf"
                },
                {
                    type: "presentation",
                    url: "slides/ictopenPresentation.pdf"
                },
            ]
        },
        {
            title: "Exploring DSL evolutionary patterns in practice: a study of DSL evolution in a large-scale industrial DSL repository",
            img: "prizes/tue.png",
            url: "https://research.tue.nl/files/93550998/mengexpl2017.pdf",
            authors: "J.G.M. Mengerink, B. van der Sanden, B.C.M. Cappers, A. Serebrenik, R.R.H. Schiffelers, and M.G.J. van den Brand",
            conference: "Proceedings of the International Conference on Model-Driven Engineering and Software Development (MODELSWARD), (2018)",
            links: [
                {
                    type: "pdf",
                    url: "https://research.tue.nl/files/93550998/mengexpl2017.pdf"
                }
            ]
        },
        {
            title: "Visual Analysis of Parallel Interval Events",
            img: "prizes/tue.png",
            url: "https://www.researchgate.net/publication/324719902_Visual_Analysis_of_Parallel_Interval_Events",
            authors: "J. Qi, C. Liu, B.C.M. Cappers, and H.M.M. van de Wetering",
            conference: "EuroVis, (2018)",
            awards: ["EuroVis Best Short Paper Award 2018"],
            links: [
                {
                    type: "pdf",
                    url: "https://www.researchgate.net/publication/324719902_Visual_Analysis_of_Parallel_Interval_Events"
                }
            ]
        },
        {
            title: "Predictive analytics to prevent voice over ip international revenue sharing fraud",
            img: "prizes/tue.png",
            url: "https://link.springer.com/chapter/10.1007/978-3-030-49669-2_14",
            authors: "Y.J. Meijaard, B. Cappers, J.G.M. Mengerink, N. Zannone",
            conference: "IFIP Annual Conference on Data and Applications Security and Privacy. Springer, Cham, 2020",
            links: [
                {
                    type: "pdf",
                    url: "https://link.springer.com/chapter/10.1007/978-3-030-49669-2_14"
                }
            ]
        },
        {
            title: "Exploring and visualizing GLL parsing",
            img: "media/gll.jpg",
            url: "papers/thesis.pdf",
            authors: "Bram C.M. Cappers",
            conference: "Master Thesis Computer Science & Engineering, 2014",
            links: [
                {
                    type: "pdf",
                    url: "papers/thesis.pdf"
                },
                {
                    type: "video",
                    url: "videos/GLLExplorer.mp4"
                },
                {
                    type: "presentation",
                    url: "https://slideplayer.com/slide/12797709/"
                }
            ]
        }
    ];

    const videoItems: VideoCardItem[] = [
        {
            name: "Chapter 3",
            video: <iframe width={"100%"} height={"100%"}
                           title={"SNAPS: Network traffic Analysis through Projection and Selection."}
                           src="https://www.youtube.com/embed/aYywTOYjYDA" frameBorder="0"
                           allow="autoplay; encrypted-media" allowFullScreen/>
        },
        {
            name: "Chapter 4",
            video: <iframe width={"100%"} height={"100%"}
                           title={"Understanding the Context of Network Traffic Alerts."}
                           src="https://www.youtube.com/embed/yOXDZYKCKZ0" frameBorder="0"
                           allow="autoplay; encrypted-media" allowFullScreen/>
        },
        {
            name: "Chapter 5",
            video: <iframe width={"100%"} height={"100%"}
                           title={"Exploring Multivariate Event Sequences using Rules, Aggregations and Selections."}
                           src="https://www.youtube.com/embed/2DWVW-vLN8Q" frameBorder="0"
                           allow="autoplay; encrypted-media" allowFullScreen/>
        },
        {
            name: "Chapter 6",
            video: <iframe width={"100%"} height={"100%"}
                           title={"Exploring Lekagul Sensor Events using Rules, Aggregations, and Selections."}
                           src="https://www.youtube.com/embed/IBgJ3R9cAvQ" frameBorder="0"
                           allow="autoplay; encrypted-media" allowFullScreen/>
        },
        {
            name: "Chapter 7",
            video: <iframe width={"100%"} height={"100%"}
                           title={"Eventpad: Rapid Malware Analysis and Reverse Engineering using Visual Analytics."}
                           src="https://www.youtube.com/embed/g4brXOtPELI" frameBorder="0"
                           allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                           allowFullScreen/>
        }
    ];

    const coolStuff: LinkCardItem[] = [
        {
            name: "SPH Particle Simulation",
            img: "misc/hourglass.png",
            url: "videos/hourglass.mp4"
        },
        {
            name: "Interactive Led Lights",
            img: "misc/light.png",
            url: "videos/lights.mp4"
        },
        {
            name: "EventPad",
            img: "media/eventPad.png",
            url: "/eventpad"
        }
    ];

    const coolStuffImgData = [
        {
            name: "Wolf",
            img: "misc/wolf.JPG"
        },
        {
            name: "PeterPan",
            img: "misc/peterpan.JPG"
        },
        {
            name: "3DHouse",
            img: "misc/3dhouse.JPG"
        },
        {
            name: "Covers",
            img: "misc/covers.JPG"
        },
        {
            name: "Beauty",
            img: "misc/beauty.jpg"
        },
        {
            name: "Heart",
            img: "misc/heart.JPG"
        },
        {
            name: "Origami",
            img: "misc/origami.JPG"
        },
        {
            name: "Paint",
            img: "misc/paint.JPG"
        },
    ];

    const coolStuffItems = coolStuff.map((item, i) => <LinkCard key={i} item={item}/>);
    coolStuffItems.push(<Card>
        <Carousel>
            {
                coolStuffImgData.map((imgItem, j) => {
                    return <Carousel.Item key={j}>
                        <div style={{
                            width: "100%",
                            height: "200px",
                            objectFit: "contain",
                            padding: "10px",
                            borderBottom: "1px solid rgb(223,223,223)"
                        }}>
                            <img style={{
                                width: "100%",
                                height: "200px",
                                objectFit: "contain",
                            }}
                                 src={imgItem.img}/>
                        </div>
                    </Carousel.Item>
                })
            }
        </Carousel>
        <Card.Body>
            <Card.Title style={{textAlign: "center"}}><small>Other Cool stuff</small></Card.Title>
        </Card.Body>
    </Card>);

    return (<Container fluid className="px-lg-4 px-xl-5">
        <Section name={"Introduction"}>
            <p>I'm Bram and I am a computer scientist from <a href="http://www.tue.nl/en">Eindhoven University of
                Technology.</a></p>
            <p>Ever since I was little, I have been fascinated about the functioning and actuation of electronic
                devices. I love to puzzle on mathematical problems and try to come up with a creative solution. My
                interests are in particular in the area of data visualization, information systems, formal system
                analysis, and language engineering.</p>
            <p>I have finished my PhD in the area of visual analytics
                and am co-founder of the companies:
                <ul>
                    <li>
                        <a href="http://www.codenext21.com">CodeNext21</a> to build next-generation transparent AI solutions and
                    </li>
                    <li>
                        <a href="http://www.tibo.energy">Tibo Energy</a> to assist companies in better orchestrating their energy management.
                    </li>
                </ul>
            </p>
            <p>Besides my academic career as Univeristy Lecturer, I like to give courses & provide training material on the application of Data-science and AI for industrial development and process automation.</p>
            <p>When I am back at home, I love to watch a movie and hang out with my friends. My hobbies are drawing, diving, bouldering, and modeling.</p>
            <p>For more information about acquired awards, achievements and grants, check out the <a
                href={"/trac"}>traction</a> page</p>
        </Section>

        <Section name={"News"}>
            <ListView items={newItems.map((newsItem, i) => <ListCard key={i} item={newsItem}/>)}/>
        </Section>

        <Section name={"Cool Stuff"}>
            <GridView items={coolStuffItems}/>
        </Section>

        <Section name={"Dissertation"}>
            <ListCard item={{
                name: <span>Phd Dissertation</span>,
                img: "media/cover.png",
                url: "files/Cappers2018.pdf",
                description: <span>
                Check out my dissertation on <a href={"/files/Cappers2018.pdf"}><b>Interactive Visualization of Events Logs for Cybersecurity</b></a>.
               </span>
            }}/>

            <div style={{textAlign: "left"}}>
                <h4>Chapter Video's</h4>
                <Row>
                    {
                        videoItems.map((item, i) => {
                            return (
                                <Col key={i} lg={6} md={6} sm={6}><VideoCard item={item}/></Col>);
                        })
                    }
                </Row>
            </div>
        </Section>

        <Section name={"Publications"}>
            <ListView items={paperItems.map((paperItem, i) => <PaperCard key={i} item={paperItem}/>)}/>
        </Section>

        <Section name={"Phd Research"}>
            <p>The main research question for my PhD project <b>SpySpot</b> is:</p>
            <p><i>``How can we use visualization techniques to detect (or aid in the detection of) cyber espionage and
                targeted malware in computer networks using deep packet inspection and automated anomaly detection
                techniques?''’</i></p>

            <Image src="/media/spyspot3.png" width="350px" style={
                {
                    float: "right",
                    marginLeft: "10px"
                }
            } alt=""/>
            <p>One of the main challenges in the area of network traffic analysis is how to detect when a network is
                being exploited (e.g., cyber espionage, exfiltration, targeted malware). Especially for critical
                infrastructures (such as power plants), hackers nowadays are willing to design complex viruses to
                maximize the damage in one specific infrastructure. The main difficulty with Advanced Persistent Threats
                (APTs) is the involvement of domain knowledge such that their traffic can no longer be distinguished
                from regular activity by simple inspection of high level properties (e.g., message length and
                destination address).</p>
            <p>Current methods focus on the analysis of these properties, since in practice they have shown to be
                sufficient for the discovery of traditional attacks (e.g., buffer overflows, DDOS attacks). The fact
                that these techniques consider traffic content as a black box makes them unaware of anomalies at the
                level of semantics. The goal of SpySpot is to combine anomaly based deep packet inspection with
                visualization to lay the basis of a new generation of security monitoring tools that are suitable to
                detect advanced persistent threats. The analysis part enables the system to automatically “spot”
                anomalous behavior in network traffic whereas visualization enables the user to gain insight in these
                alerts and allow them to act accordingly.</p>

            <p>The motivation for visualizing network traffic is three-fold:</p>
            <ul>
                <li>Enhance analysis part through iterative refinement:</li>
                <ul>
                    <li>Use interaction to report false positive alerts back to the SpySpot detection system to prevent
                        these alerts from happening in the future. This enables the user to optimize the SpySpot system
                        according to their environment.
                    </li>
                </ul>
                <li>Gain insight in alerts and traffic through visual explanation:</li>
                <ul>
                    <li>Use visualization to combine analysis results and user domain knowledge to relate low-level
                        technical alerts to high-level network events.
                    </li>
                    <li>Enable users to visually distinguish true alerts from typically a large collection of false
                        alerts by presenting and comparing data in various contexts.
                    </li>
                </ul>
                <li>Discover Anomalies through visual traffic exploration:</li>
                <ul>
                    <li>Discover non-trivial network patterns and anomalies that are outside the scope of the analysis
                        part.
                    </li>
                </ul>
            </ul>
        </Section>
    </Container>);
}
