import React, {PropsWithChildren} from "react";
import {faFilePdf, faFilePowerpoint, faFilm, faLink, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export interface LinkType{
    type : "pdf" | "video" | "presentation"
    url : string
}

export interface LinkItemProps extends PropsWithChildren<any> {
    link: LinkType
}

export const LinkItem: React.FC<LinkItemProps> = (props) => {
    let icon : IconDefinition = faLink;

    switch (props.link.type){
        case "pdf":
            icon = faFilePdf;
            break;
        case "video":
            icon = faFilm;
            break;
        case "presentation":
            icon = faFilePowerpoint
            break;
    }

    return <a href={props.link.url}><FontAwesomeIcon {...props} icon={icon} size={"2x"}/></a>;
}
