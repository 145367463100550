import {Badge, Card, Col, Row} from "react-bootstrap";
import React, {PropsWithChildren} from "react";

export type CVType = "education" | "work" | "award" | "charity"; //| "research"
export const cvTypes : CVType[] = ["education", "work", "award", "charity"]; //"research",

export type CVCardItem = {
    name: React.ReactNode,
    positition: React.ReactNode,
    period: string,
    img: string,
    type: CVType,
    activity : React.ReactNode
    tags : string[]
}

export interface CVCardProps extends PropsWithChildren<any> {
    item: CVCardItem
}

export const CVCard: React.FC<CVCardProps> = (props) => {
    return (
            <Card >
                <Row  className="g-0 align-items-center">
                    <Col md={3}>
                        <Card.Img variant="top" src={props.item.img} style={{
                            width: "100%",
                            height: "80px",
                            objectFit: "contain",
                            padding: "10px",
                        }}/>
                    </Col>
                    <Col md={8}>
                        <Card.Body className="card-body" style={{
                            textAlign : "left",
                        }}>
                            <Card.Title className={"card-title h6"}>{props.item.name}<br/><small className="text-muted">{props.item.positition}</small></Card.Title>
                        </Card.Body>
                    </Col>
                </Row>
                <Row style={{
                    textAlign : "left",
                    padding: "10px"
                }}>

                    <Col md={12}>
                        <small>{props.item.activity}</small>
                        <br/>
                        <br/>
                        {props.item.tags.map((tag,i) => <Badge pill key={i} bg={"secondary"}>{tag}</Badge>)}
                    </Col>

                </Row>
            </Card>
    );
}
