import {Card} from "react-bootstrap";
import React, {PropsWithChildren} from "react";

export type LinkCardItem = {
    name: React.ReactNode,
    img: string,
    url: string
}

export interface LinkCardProps extends PropsWithChildren<any> {
    item: LinkCardItem
}

export const LinkCard: React.FC<LinkCardProps> = (props) => {
    return (
        <a href={props.item.url} style={{
            textDecoration : "none",
            color:"#314496"
        }}>
            <Card className={'mb-2'}>
                <Card.Img variant="top" src={props.item.img} style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "contain",
                    padding: "10px",
                    borderBottom: "1px solid rgb(223,223,223)"
                }}/>
                <Card.Body>
                    <Card.Title style={{textAlign: "center"}}><small>{props.item.name}</small></Card.Title>
                </Card.Body>
            </Card>
        </a>);
}
