import {PropsWithChildren} from "react";

export interface SectionProps extends PropsWithChildren<any>{
    name : string
}

export const Section : React.FC<SectionProps> = (props) => {
    return (<div style={{
        textAlign: "left"
    }}>
        <h2>{props.name}</h2>
        <hr
            style={{
                color: "#000000",
                backgroundColor: "#000000",
                height: 2
            }}
        />
        {props.children}
    </div>);
}
